export const fetchCohortSurveyChildListStart = (state, payload) => {
    return {
        ...state,
        fetchCohortSurveyChildListingApi: {
            ...state.fetchCohortSurveyChildListingApi,
            [payload.projectId]: {
                ...state.fetchCohortSurveyChildListingApi[payload.projectId],
                isProcessing: true,
                error: false, reason: '',
                pageNumber: payload.from ? payload.from : 1,
                sortField: payload.sortField ? payload.sortField : "projectCreationDate",
                sortOrder: payload.sortOrder ? payload.sortOrder : "descend",

            },
            isProcessing: true,
            error: false, reason: '',
        },
    };
};

export const fetchCohortSurveyChildListSuccess = (state, payload) => {
    return {
        ...state,
        fetchCohortSurveyChildListingApi: {
            ...state.fetchCohortSurveyChildListingApi,
            [payload.payload.projectId]: {
                ...state.fetchCohortSurveyChildListingApi[payload.payload.projectId],
                isProcessing: false,
                error: false, reason: '',
                data: payload.data.data.hits,
                count: payload.data.data.total
            },
            isProcessing: false,
            error: false, reason: '',
        },
    };
};

export const fetchCohortSurveyChildListFail = (state, payload) => {
    return {
        ...state,
        fetchCohortSurveyChildListingApi: {
            ...state.fetchCohortSurveyChildListingApi,
            isProcessing: false,
            error: true,
            reason: payload.error,
        },
    };
};
