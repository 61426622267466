import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const teamDevelopment = {
    "blockId": uuidv4(),
    "description": "<p>Grow your team: Optimize teams' potential, capacity, and performance by building high performing collaborative teams that are inspired and connected to the bigger picture, accountable, driven, and inclusive.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategory":"Increase Clarity & Accountability",
                    "staticQuestionId": generateStaticQuestionId("Clearly communicates team goals, expectations, and timelines"),
                    "HTMLText": "<p>Clearly communicates team goals, expectations, and timelines</p>",
                    "isSelected": true,
                    "text": "Clearly communicates team goals, expectations, and timelines",
                    "value": uuidv4()
                },
                // {
                //     "staticQuestionId": generateStaticQuestionId("Assigns clear accountability for objectives."),
                //     "HTMLText": "<p>Assigns clear accountability for objectives.</p>",
                //     "isSelected": true,
                //     "text": "Assigns clear accountability for objectives.",
                //     "value": uuidv4()
                // },
                {
                    "subCategory":"Optimize Resources & Capabilities",
                    "staticQuestionId": generateStaticQuestionId("Uses team members' skills appropriately to enhance team performance"),
                    "HTMLText": "<p>Uses team members' skills appropriately to enhance team performance</p>",
                    "isSelected": true,
                    "text": "Uses team members' skills appropriately to enhance team performance",
                    "value": uuidv4()
                },
                {
                    "subCategory":"Enable Team Learning & Improvement",
                    "staticQuestionId": generateStaticQuestionId("Ensures the team has the resources needed to successfully achieve its objectives"),
                    "HTMLText": "<p>Ensures the team has the resources needed to successfully achieve its objectives</p>",
                    "isSelected": true,
                    "text": "Ensures the team has the resources needed to successfully achieve its objectives",
                    "value": uuidv4()
                },

                
                {
                    "subCategory":"Increase Clarity & Accountability",
                    "staticQuestionId": generateStaticQuestionId("Holds the team accountable for achieving results"),
                    "HTMLText": "<p>Holds the team accountable for achieving results</p>",
                    "isSelected": true,
                    "text": "Holds the team accountable for achieving results",
                    "value": uuidv4()
                },
                {
                    "subCategory":"Increase Clarity & Accountability",
                    "staticQuestionId": generateStaticQuestionId("Ensures the team is continually monitoring its performance and identifying any performance issues"),
                    "HTMLText": "<p>Ensures the team is continually monitoring its performance and identifying any performance issues</p>",
                    "isSelected": true,
                    "text": "Ensures the team is continually monitoring its performance and identifying any performance issues",
                    "value": uuidv4()
                },

{
                    "subCategory":"Optimize Resources & Capabilities",
    "staticQuestionId": generateStaticQuestionId("Addresses capability gaps in the team by developing team members and creating the right team composition"),
    "HTMLText": "<p>Addresses capability gaps in the team by developing team members and creating the right team composition</p>",
                    "isSelected": true,
    "text": "Addresses capability gaps in the team by developing team members and creating the right team composition",
                    "value": uuidv4()
                },


                {
                    "subCategory":"Enable Team Learning & Improvement",
                    "staticQuestionId": generateStaticQuestionId("Helps team members resolve their conflicts constructively"),
                    "HTMLText": "<p>Helps team members resolve their conflicts constructively</p>",
                    "isSelected": true,
                    "text": "Helps team members resolve their conflicts constructively",
                    "value": uuidv4()
                },
                 
                {
                    "subCategory":"Enable Team Learning & Improvement",
                    "staticQuestionId": generateStaticQuestionId("Identifies and removes barriers to team performance that team members cannot solve themselves"),
                    "HTMLText": "<p>Identifies and removes barriers to team performance that team members cannot solve themselves</p>",
                    "isSelected": true,
                    "text": "Identifies and removes barriers to team performance that team members cannot solve themselves",
                    "value": uuidv4()
                },

                {
                    "subCategory": "Enable Team Learning & Improvement",
                    "staticQuestionId": generateStaticQuestionId("Recognizes team members who demonstrate collaboration and teamwork"),
                    "HTMLText": "<p>Recognizes team members who demonstrate collaboration and teamwork</p>",
                    "isSelected": true,
                    "text": "Recognizes team members who demonstrate collaboration and teamwork",
                    "value": uuidv4()
                },

                {
                    "subCategory":"Enable Team Learning & Improvement",
                    "staticQuestionId": generateStaticQuestionId("Shows trust in team by delegating work and decisions, as appropriate"),
                    "HTMLText": "<p>Shows trust in team by delegating work and decisions, as appropriate</p>",
                    "isSelected": true,
                    "text": "Shows trust in team by delegating work and decisions, as appropriate",
                    "value": uuidv4()
                },




            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "High Performance Team"
}