import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const coaching = {
    "blockId": uuidv4(),
    "description": "<p>Grow Your Talent: Scale capacity by maximizing the performance and potential of future talent through coaching- creating a space for others to grow, conducting meaningful conversations that are based on inclusive, trustworthy, empowering relationships.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategory":"Optimize Talent Potential",
                    "staticQuestionId": generateStaticQuestionId("Accurately identifies the strengths and development needs of team members"),
                    "HTMLText": "<p>Accurately identifies the strengths and development needs of team members</p>",
                    "isSelected": true,
                    "text": "Accurately identifies the strengths and development needs of team members",
                    "value": uuidv4()
                },
                {
                    "subCategory":"Optimize Talent Potential",
                    "staticQuestionId": generateStaticQuestionId("Provides assignments that stretch and grow team members"),
                    "HTMLText": "<p>Provides assignments that stretch and grow team members</p>",
                    "isSelected": true,
                    "text": "Provides assignments that stretch and grow team members",
                    "value": uuidv4()
                },
                {
                    "subCategory":"Drive High Performance & Capacity",
                    "staticQuestionId": generateStaticQuestionId("Encourages team members to take initiative in determining how best to get their work done"),
                    "HTMLText": "<p>Encourages team members to take initiative in determining how best to get their work done</p>",
                    "isSelected": true,
                    "text": "Encourages team members to take initiative in determining how best to get their work done",
                    "value": uuidv4()
                },

                {
                    "subCategory":"Drive High Performance & Capacity",
                    "staticQuestionId": generateStaticQuestionId("Gives clear and constructive feedback to help team members perform more effectively"),
                    "HTMLText": "<p>Gives clear and constructive feedback to help team members perform more effectively</p>",
                    "isSelected": true,
                    "text": "Gives clear and constructive feedback to help team members perform more effectively",
                    "value": uuidv4()
                },

                {
                    "subCategory":"Build Employee Engagement",
                    "staticQuestionId": generateStaticQuestionId("Shows genuine interest in team members and their well-being"),
                    "HTMLText": "<p>Shows genuine interest in team members and their well-being</p>",
                    "isSelected": true,
                    "text": "Shows genuine interest in team members and their well-being",
                    "value": uuidv4()
                },
                {
                    "subCategory":"Build Employee Engagement",
                    "staticQuestionId": generateStaticQuestionId("Adapts management style and behaviors based on the situation and needs of team members"),
                    "HTMLText": "<p>Adapts management style and behaviors based on the situation and needs of team members</p>",
                    "isSelected": true,
                    "text": "Adapts management style and behaviors based on the situation and needs of team members",
                    "value": uuidv4()
                },
                {
                    "subCategory":"Optimize Talent Potential",
                    "staticQuestionId": generateStaticQuestionId("Coaches and mentors team members on how to advance in their careers"),
                    "HTMLText": "<p>Coaches and mentors team members on how to advance in their careers</p>",
                    "isSelected": true,
                    "text": "Coaches and mentors team members on how to advance in their careers",
                    "value": uuidv4()
                },
              
                {
                    "subCategory":"Drive High Performance & Capacity",
                    "staticQuestionId": generateStaticQuestionId("Holds individual team members accountable for achieving results"),
                    "HTMLText": "<p>Holds individual team members accountable for achieving results</p>",
                    "isSelected": true,
                    "text": "Holds individual team members accountable for achieving results",
                    "value": uuidv4()
                },
             
                {
                    "subCategory":"Build Employee Engagement",
                    "staticQuestionId": generateStaticQuestionId("Recognizes emotions and provides encouragement to team members who are frustrated or disheartened"),
                    "HTMLText": "<p>Recognizes emotions and provides encouragement to team members who are frustrated or disheartened</p>",
                    "isSelected": true,
                    "text": "Recognizes emotions and provides encouragement to team members who are frustrated or disheartened",
                    "value": uuidv4()
                },

              
              
               
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Coaching"
}