import { deepCopy } from "../../../../../../utils/deepCopy"
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils"
import { getHTMLRemovedQuestion } from "../helpers"

export const matrixLabelChange = (state, payload) => {
    let updatedGoals = matrixLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const matrixLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionSection) {
                element.questions[0].options[payload.optionIndex].label = payload.value
            } else {
                element.questions[0].matrixQuestions[payload.questionIndex].text = getHTMLRemovedQuestion(payload.value)
                element.questions[0].matrixQuestions[payload.questionIndex].HTMLText = payload.value
                element.questions[0].matrixQuestions[payload.questionIndex].staticQuestionId = generateStaticQuestionId(getHTMLRemovedQuestion(payload.value))
            }
        }
    });
    return goalsCopy
}

export const matrixAddColumOrRow = (state, payload) => {
    let updatedGoals = matrixColOrRowAddedChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const matrixColOrRowAddedChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionSection) {
                element.questions[0].options.splice(element.questions[0].options.length, 0, payload.data)
                let findedNaIndex = element.questions[0].options.findIndex((item => item.value === "0"))
                if (findedNaIndex !== -1) {
                    let naOption = element.questions[0].options.splice(findedNaIndex, 1)
                    element.questions[0].options.splice(element.questions[0].options.length - 1, 0, naOption[0])

                }

            } else {
                element.questions[0].matrixQuestions.splice(element.questions[0].matrixQuestions.length, 0, payload.data)
            }
        }
    });
    return goalsCopy
}


export const matrixRemoveColumOrRow = (state, payload) => {
    let updatedGoals = matrixColOrRowRemovedChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const matrixColOrRowRemovedChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionSection && element.questions[0].options.length > 1) {
                element.questions[0].options.splice(payload.index, 1)
            } else if (!payload.optionSection && element.questions[0].matrixQuestions.length > 1) {
                element.questions[0].matrixQuestions.splice(payload.index, 1)
            }
        }
    });
    return goalsCopy
}