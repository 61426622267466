import React from 'react'
import { CustomSelect } from '../../Components/CustomSelect';
import { ButtonElement } from '../../FormElements/ButtonElement';
import { RouteHeader } from '../../Components/RouteHeader';
import { AsterikLabel } from '../../Components/AsterikLabel';
import { TextInput } from '../../FormElements/TextInput';
import { createProjectPayload, generateQuestionSetBasedOnProject, getModifyProjectDataPayload, selectHRISOptionList, submitToUpload } from './helper';
import ModalWindow from '../../Components/CsvUploadModal';
import { deepCopy } from '../../utils/deepCopy';
import { encryptJson, getDataFromUrlToken } from '../../utils/urlDataStore';
import { URL_DATA } from '../../../config/urlDataConstants';
import { COHORT_SURVEY_SECTION, SURVEY_WIZARD_TABS } from '../../../config/tabConstants';
import{getFetchHRISPayload} from './helper'
import { FEEDBACK_360_FOR_PARTICULAR_ORG_IDS, FEEDBACK_360_SURVEY } from '../../../config/questionTypeConstants';
import { Spin } from 'antd';
import constants from './constants'
import Container from '../../Components/Container';
import { Row } from 'react-bootstrap';
import "./index.scss";
import { LoaderButton } from '../../FormElements/LoaderButton';
import { toast } from '../../utils/messageUtils';
import { getNewSurveyPagePath } from '../../utils/getHomePath';
import { nlpSetURLParams } from '../../utils/nlpURLUtils';
class CohortSuvey extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            showHRISUploadModal: false,
            setUpSelectData: '1',
            showWarningModal: false,
            showAddInviteesModal: false,
            inputValue: "",
            disableButton:"",
            selectedOption: null,
            loadingState:false
        }
    }

    componentDidMount(){
        let fetchHRISPayload = getFetchHRISPayload();
        this.props.surveyWizardFetchHRISFiles({ ...fetchHRISPayload});
    }

    handleChange = (value) => {
        this.setState({
            inputValue: value.value
        })
    }
    closeHRISUploadModal = (cb) => {
        this.setState({
            ...this.state,
            showHRISUploadModal: false
        })
        this.props.handleFileUpload(null)
        cb('')
    }

    showHRISUploadModal = () => {
        this.setState({
            ...this.state,
            showHRISUploadModal: true
        })
    }

    getCSVUploadModal = () => {    
        return (
            <ModalWindow
                {...this.props}
                handleModalState={(cb) => this.closeHRISUploadModal(cb)}
                isBulkAddModalVisible={this.state.showHRISUploadModal}
                submitToUpload={(cb, fileName) => submitToUpload(cb, fileName, this)}
                handlechange = {this.handleCheckChange}
                fileToUpload = {this.props.fileToUpload}
            />
        )
    }

    hrisSelectChange = (selectedOption) => {
        this.setState({ selectedOption });

    };

    onUploadClick = (fileName,fileId)=>{
        this.props.surveyWizardFetchHRISFiles({...getFetchHRISPayload()});
        this.setState({ selectedOption:fileId });
        this.props.fileUploadStatus({ hrisFileId: fileId, currentTab:COHORT_SURVEY_SECTION.CREATE_COHORT_SURVEY }, (response) => {
            this.recallFileUploadStatusApi({ hrisFileId: fileId, currentTab: COHORT_SURVEY_SECTION.CREATE_COHORT_SURVEY }, response);
        });

    }

    recallFileUploadStatusApi = (fileUploadData,response)=>{
        if (response && response.data.data.response.hrisFilesStatus.bulkJobStatus === constants.QUEUED) {
            this.setState({disableButton:true})
           this.props.fileUploadStatus( fileUploadData, (response) => {
               setTimeout(() => {
                   this.recallFileUploadStatusApi(fileUploadData,response);
               }, 3000);
           })
       }
       else{
        this.setState({disableButton:false})
        let fetchHRISPayload = getFetchHRISPayload();
        this.props.surveyWizardFetchHRISFiles({ ...fetchHRISPayload});
        this.setState({ selectedOption:fileUploadData.hrisFileId });
       }
   }

    getHRISselectElement = () => {
        return (
            <Spin spinning={this.props.surveyWizardHrisFIleApi.isProcessing}>
                <CustomSelect
                    disabled={this.state.disableButton}
                    disableSearch={true}
                    value={this.state.selectedOption}
                    placeholder={"Select HRIS"}
                    optionList={selectHRISOptionList({ hrisData: this.props.surveyWizardHrisFIleApi.data, hrisFilesStatus: this.props.HRISUploadStatus.data ? this.props.HRISUploadStatus.data.hrisFilesStatus : {},isFromCohort:true})}
                    className="participants-select"
                    onChange={this.hrisSelectChange}
                />
             </Spin>
        );
    }

    routeToProjectsPage=()=>{
        const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
        const org_id = orgDetails.organizationId
        const encryptedOrgDetails = encryptJson({ orgDetails });
        this.props.history.push({
            pathname: `/home/${org_id}/surveys/cohortTab`,
            search: nlpSetURLParams({ params: encryptedOrgDetails }),
        });

    }
    createCohortProject = () => {
        this.setState({ loadingState: true });
        let payload = createProjectPayload(this)
        this.props.NewProjectPageCreateProject(
            { ...payload },
            (success, response) => {
            this.callBack(success, response)}
        )
    }

    callBack = (success, response) => {
        if (success) {
            toast.success(constants.PROJECT_CREATE_MESSAGE)
            const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
            const orgId = orgDetails.organizationId
            let questionSet = generateQuestionSetBasedOnProject({ ...this.props, projectType: FEEDBACK_360_FOR_PARTICULAR_ORG_IDS.includes(orgId) ? FEEDBACK_360_SURVEY.DYNAMIC_360_APPLIED_MATERIALS : FEEDBACK_360_SURVEY.DYNAMIC })
            let updatedDoc = {
                questionSet: questionSet, aboutSurvey: constants.ABOUT_SURVEY_PLACEHOLDER,
                questionSetUpdatedDate: new Date().toISOString(),
                endMessage: constants.END_MESSAGE_PLACEHOLDER
            }
            let payload = getModifyProjectDataPayload(updatedDoc, deepCopy(response))
            this.props.surveyWizardModifyProjectData({ ...payload }, (sucess) => {
                if (sucess) {
                    this.setState({ loadingState: false })
                    this.routeToProjectsPage()
                } else {
                    this.setState({ loadingState: false })
                    toast.error(constants.ERROR_TOAST)
                }

            })

        }
        else {
            this.setState({ loadingState: false })
            toast.error(constants.ERROR_TOAST_CREATE_PROJECT)
        }
    }

    checkIfButtonDisabled = () => {
        if (!this.state.inputValue.trim().length > 0 || !this.state.selectedOption || this.state.disableButton) {
            return true
        }
        else {
            return false
        }
    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loadingState} tip={constants.LOADING_SPINNER_TEXT}>
             <Container>
                <Row>
               
                <RouteHeader
          {...this.props}
          navigationData={constants.navigationList}
          title={"Cohort survey"}
        />
        
        <div className='CohortSuvey'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
            <div className='CohortSuvey__Left'>
             {this.getCSVUploadModal()}
       
                <h4>Choose HRIS file to send </h4>

                    <>
                        {this.getHRISselectElement()}
                        <ButtonElement
                            disabled={this.state.disableButton}
                            type='primary'
                            onClick={this.showHRISUploadModal}
                        >
                            Upload new HRIS file
                        </ButtonElement>
                        <h4>
                            Enter project name <AsterikLabel />
                        </h4>
                        <TextInput
                        placeholder="Enter survey name"
                        value={this.state.inputValue}
                        handleChange={this.handleChange}
                         />


                        <LoaderButton
                            onClick={this.createCohortProject}
                            disabled={this.checkIfButtonDisabled()}
                            classList={constants.loaderButton.classes}
                            loaderClass={constants.loaderButton.loaderClass}
                            text={constants.BUTTON_NAME}
                        />
                    </>
      </div>

      </div>
      {/* <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12'>Cohort survey
</div> */}
      </div>
      </Row>
      </Container>
                </Spin>
      </>
        )
    }
}
export default CohortSuvey;
