
export const fetchCommentsSentimentsDataStart = (state, payload) => {
  
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      isProcessing: true,
      isDataAlreadyFetched: payload.isDataAlreadyFetched,
      data:
      payload.from === 0
      ? null
      : state[payload.selectedQuestionId].data != null
      ? state[payload.selectedQuestionId].data
      : null,
     
      error: null,
      message: null,
      pagination: false,
      initialLoading: payload.initialLoading,
      searchText:payload.searchText
    },
  };
};

export const fetchCommentsSentimentsDataSuccess = (state, payload) => {
  let pagination;
  let commentsList = [];
  if (payload.data.data) { 
  if (payload.pagination) {
   
    pagination = true;
    commentsList = [
      ...state[payload.selectedQuestionId].data.data,
      ...payload.data.data,
    ];
  } else {
    pagination = false;
    commentsList = payload.data.data;
  }
}

  return {
    ...state,
    [payload.selectedQuestionId]: {
     ...state[payload.selectedQuestionId],
      data: {
        ...payload.data,
        data: commentsList,
      },
      isDataAlreadyFetched: true,
      isProcessing: false,
      pagination: pagination,
      filters: payload.selectedfilters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
      from:payload.from,
      searchText:payload.searchText
    },
    selectedQuestionId: payload.selectedQuestionId,
  };
};

export const fetchCommentsSentimentsDataFailed = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      data: null,
      isProcessing: false,
      isDataAlreadyFetched: false,
      topics: [],
      error: "error",
      pagination: false,
    },
  };
};

export const updateCommentQuestionId = (state, payload) => {
  return {
    ...state,
    selectedQuestionId: payload,
  };
};


export const updateSearchkey = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]:{
      ...state[payload.selectedQuestionId],
      searchText:payload.searchText
    }
  };
};
