import {
  FETCH_SURVEY_LIST_STARTED,
  FETCH_SURVEY_LIST_SUCCESS,
  FETCH_SURVEY_LIST_FAILED,
  FETCH_SURVEY_DETAILS_STARTED,
  FETCH_SURVEY_DETAILS_SUCCESS,
  FETCH_SURVEY_DETAILS_FAILED,
  FETCH_TREE_DATA_STARTED,
  FETCH_TREE_DATA_SUCCEED,
  FETCH_TREE_DATA_FAILED,
  FETCH_DASHBOARD_FILTER_START,
  FETCH_DASHBOARD_FILTER_SUCCESS,
  FETCH_DASHBOARD_FILTER_FAILED,
  FETCH_SURVEY_REPORT_START,
  FETCH_SURVEY_REPORT_SUCCESS,
  FETCH_SURVEY_REPORT_FAILED,
  FETCH_COMPLETE_SURVEY_LIST_STARTED,
  FETCH_COMPLETE_SURVEY_LIST_SUCCESS,
  FETCH_COMPLETE_SURVEY_LIST_FAILED,
  FETCH_OVERALL_EXPLORE_PARTICIPATION_FAILED,
  FETCH_OVERALL_EXPLORE_PARTICIPATION_SUCCESS,
  FETCH_OVERALL_EXPLORE_PARTICIPATION_START,
  FETCH_STORYBOARD_ELEMNTS_STARTED,
  FETCH_STORYBOARD_ELEMNTS_SUCCESS,
  FETCH_STORYBOARD_ELEMNTS_FAILED,
  FETCH_STORYBOARD_STATUS_STARTED,
  FETCH_STORYBOARD_STATUS_SUCCESS,
  FETCH_STORYBOARD_STATUS_FAILED,
  STORYBOARD_STATUS_CHANGE,
  STORYBOARD_FILTER_CHANGE,
  SET_THRESHOLD_VALUE_SUCCESS,
  SET_THRESHOLD_VALUE_FAILED,
  SET_THRESHOLD_VALUE_STARTED,
  GET_THRESHOLD_VALUE_SUCCESS,
  GET_THRESHOLD_VALUE_FAILED,
  GET_THRESHOLD_VALUE_STARTED,
  FETCH_STORYBOARD_FILTER_START,
  FETCH_STORYBOARD_FILTER_SUCCESS,
  FETCH_STORYBOARD_FILTER_FAIL,
  SET_SELECTED_COMPARISON,
  SET_SELECTED_PALETTE,
  SET_SELECTED_RANGE_SELECTOR,
  SAVE_HEATMAP_CONFIGURATION_SUCCESS,
  SAVE_HEATMAP_CONFIGURATION_START,
  SAVE_HEATMAP_CONFIGURATION_FAIL,
  SET_SELECTED_DELTA_VALUE,
  SET_SELECTED_THRESHOLD_VALUE,
  ADD_COMPONENT,
  REMOVE_COMPONENT

} from '../../constants';
import { initialState } from './initialState';
import {
  fetchSurveyListStart,
  fetchSurveyListSuccess,
  fetchSurveyListFail,
  fetchSurveyDetailsStart,
  fetchSurveyDetailsSuccess,
  fetchSurveyDetailsFail,
  fetchTreeDataStart,
  fetchTreeDataSuccess,
  fetchTreeDataFail,
  fetchDashBoardFilterStart,
  fetchDashBoardFilterSuccess,
  fetchDashBoardFilterFail,
  fetchSurveyReportStart,
  fetchSurveyReportSuccess,
  fetchSurveyReportFail,
  fetchCompletedSurveyListStart,
  fetchCompletedSurveyListSuccess,
  fetchCompletedSurveyListFail,
  exploreOverAllParticipationDataStart,
  exploreOverAllParticipationDataSuccess,
  exploreOverAllParticipationDataFailed,
  fetchStoryboardElementsStart,
  fetchStoryboardElementsSuccess,
  fetchStoryboardElementsFail,
  fetchStoryboardFilterStatusStart,
  fetchStoryboardFilterStatusSuccess,
  fetchStoryboardFilterStatusFail,
  clearStoryboardFilterStatus,
  changeStoryboardFilter,
  setThresholdValueSuccess,
  setThresholdValueStarted,
  setThresholdValueFail,
  getThresholdValueSuccess,
  getThresholdValueFail,
  getThresholdValueStarted,
  selectedPalette,
  selectedRange,
  selectedComparison,
  setThresholdRangeValue,
  setDeltaRangeValue,
} from './helper';
import { fetchStoryboardFilterFail, fetchStoryboardFilterStart, fetchStoryboardFilterSucess } from './helper/storyboardFilter';
import { saveHeatmapConfigStart, saveHeatmapConfigSuccess, saveHeatmapConfigfail } from './helper/saveHeatmapConfiguration';
import { addComponentButton } from './helper/addComponentButton';
import { removeComponentButton } from './helper/removeComponentButton';

const DashBoardReducer = (state = initialState(), action) => {
  switch (action.type) {
    case FETCH_SURVEY_LIST_STARTED:
      return fetchSurveyListStart(state, action.payload);
    case FETCH_SURVEY_LIST_SUCCESS:
      return fetchSurveyListSuccess(state, action.payload);
    case FETCH_SURVEY_LIST_FAILED:
      return fetchSurveyListFail(state, action.payload);
    case FETCH_SURVEY_DETAILS_STARTED:
      return fetchSurveyDetailsStart(state, action.payload);
    case FETCH_SURVEY_DETAILS_SUCCESS:
      return fetchSurveyDetailsSuccess(state, action.payload);
    case FETCH_SURVEY_DETAILS_FAILED:
      return fetchSurveyDetailsFail(state, action.payload);
    case FETCH_TREE_DATA_STARTED:
      return fetchTreeDataStart(state, action.payload);
    case FETCH_TREE_DATA_SUCCEED:
      return fetchTreeDataSuccess(state, action.payload);
    case FETCH_TREE_DATA_FAILED:
      return fetchTreeDataFail(state, action.payload);
    case FETCH_DASHBOARD_FILTER_START:
      return fetchDashBoardFilterStart(state, action.payload);
    case FETCH_DASHBOARD_FILTER_SUCCESS:
      return fetchDashBoardFilterSuccess(state, action.payload);
    case FETCH_DASHBOARD_FILTER_FAILED:
      return fetchDashBoardFilterFail(state, action.payload);
    case FETCH_COMPLETE_SURVEY_LIST_STARTED:
      return fetchCompletedSurveyListStart(state, action.payload);
    case FETCH_COMPLETE_SURVEY_LIST_SUCCESS:
      return fetchCompletedSurveyListSuccess(state, action.payload);
    case FETCH_COMPLETE_SURVEY_LIST_FAILED:
      return fetchCompletedSurveyListFail(state, action.payload);
    case FETCH_OVERALL_EXPLORE_PARTICIPATION_START:
      return exploreOverAllParticipationDataStart(state, action.payload);
    case FETCH_OVERALL_EXPLORE_PARTICIPATION_SUCCESS:
      return exploreOverAllParticipationDataSuccess(state, action.payload);
    case FETCH_OVERALL_EXPLORE_PARTICIPATION_FAILED:
      return exploreOverAllParticipationDataFailed(state, action.payload);
    case SET_SELECTED_COMPARISON:
      return selectedComparison(state,action.payload);
    case SET_SELECTED_RANGE_SELECTOR:
      return selectedRange(state,action.payload)
    case SET_SELECTED_PALETTE:
      return selectedPalette(state,action.payload)
    case SET_SELECTED_DELTA_VALUE:
      return setDeltaRangeValue(state,action.payload)
    case SET_SELECTED_THRESHOLD_VALUE:
      return setThresholdRangeValue(state,action.payload)
    case ADD_COMPONENT:
      return addComponentButton(state,action.payload)
    case REMOVE_COMPONENT:
      return removeComponentButton(state,action.payload)
    default:
      return moreReducerFunctions(state, action);
  }
};

const moreReducerFunctions = (state, action) => {
  switch (action.type) {
    case FETCH_SURVEY_REPORT_START:
      return fetchSurveyReportStart(state, action.payload);
    case FETCH_SURVEY_REPORT_SUCCESS:
      return fetchSurveyReportSuccess(state, action.payload);
    case FETCH_SURVEY_REPORT_FAILED:
      return fetchSurveyReportFail(state, action.payload);
    case FETCH_STORYBOARD_ELEMNTS_STARTED:
      return fetchStoryboardElementsStart(state, action.payload);
    case FETCH_STORYBOARD_ELEMNTS_SUCCESS:
      return fetchStoryboardElementsSuccess(state, action.payload);
    case FETCH_STORYBOARD_ELEMNTS_FAILED:
      return fetchStoryboardElementsFail(state, action.payload);
    case FETCH_STORYBOARD_STATUS_STARTED:
      return fetchStoryboardFilterStatusStart(state, action.payload);
    case FETCH_STORYBOARD_STATUS_SUCCESS:
      return fetchStoryboardFilterStatusSuccess(state, action.payload);
    case FETCH_STORYBOARD_STATUS_FAILED:
      return fetchStoryboardFilterStatusFail(state, action.payload);
    case STORYBOARD_STATUS_CHANGE:
      return clearStoryboardFilterStatus(state);
    case STORYBOARD_FILTER_CHANGE:
      return changeStoryboardFilter(state, action.payload);
    case SET_THRESHOLD_VALUE_SUCCESS:
      return setThresholdValueSuccess(state, action.payload);
    case SET_THRESHOLD_VALUE_FAILED:
      return setThresholdValueFail(state, action.payload);
    case SET_THRESHOLD_VALUE_STARTED:
      return setThresholdValueStarted(state, action.payload);
    case GET_THRESHOLD_VALUE_SUCCESS:
      return getThresholdValueSuccess(state, action.payload);
    case GET_THRESHOLD_VALUE_FAILED:
      return getThresholdValueFail(state, action.payload);
    case GET_THRESHOLD_VALUE_STARTED:
      return getThresholdValueStarted(state, action.payload);
    case FETCH_STORYBOARD_FILTER_START:
      return fetchStoryboardFilterStart(state, action.payload);
    case FETCH_STORYBOARD_FILTER_SUCCESS:
      return fetchStoryboardFilterSucess(state, action.payload);
    case FETCH_STORYBOARD_FILTER_FAIL:
      return fetchStoryboardFilterFail(state, action.payload);
    case SAVE_HEATMAP_CONFIGURATION_START:
      return saveHeatmapConfigStart(state,action.payload)
    case SAVE_HEATMAP_CONFIGURATION_SUCCESS:
      return saveHeatmapConfigSuccess(state,action.payload)
    case SAVE_HEATMAP_CONFIGURATION_FAIL:
      return saveHeatmapConfigfail(state,action.payload)
    default:
      return state;
  }
};

export default DashBoardReducer;
