export const COHORT_HRIS_FILE_TYPE = "cohortHris"

export default{
    PROJECT_CREATE_MESSAGE: "Project created successfully",
    ABOUT_SURVEY_PLACEHOLDER: "<p>This&nbsp;<strong>360° feedback</strong>&nbsp;tool is designed to support continuous development and growth by capturing feedback and feedforward ideas from diverse perspectives.</p><p>We believe having ongoing two-way conversations around leaders' strengths, backhands, and impact is one of the best ways to get developmental feedback and grow. This is&nbsp;<strong><em>one additional tool&nbsp;</em></strong>that can be&nbsp;used to take a snapshot of different perspectives on how leaders are demonstrating the behaviors that help us win.</p><p>Please reflect on your insights, observations, and experiences,&nbsp;and share your perspectives. Response will remain confidential, so please be honest and open. It takes approximately 15 minutes to share your thoughts.</p><p><strong>Instructions:</strong></p><p>- Use the arrow buttons in the bottom right corner of the screen to advance pages or go back and forth as you complete the form.</p><p>- You will be asked to rate the frequency with which behaviors are demonstrated. The scale includes the terms Rarely, Not Often Enough, Often, Almost Always and Can't Answer. </p><p>- Share your perspective on how often you feel behaviors are demonstrated by the leader, selecting <u> at least </u> 3 behaviors as Strengths and <u> at least </u> 3 behaviors at Improvement Areas. This is required to complete the form.</p><p><strong>Tips for Sharing Feedback:</strong></p><p>- We all have backhands (i.e. not go-to strengths) we can improve upon. It's common and we'd expect people aren't demonstrating some behaviors consistently enough.</p><p>- Please share honest feedback and&nbsp;<strong>select 'Not Enough'&nbsp;</strong>and&nbsp;<strong>'Rarely'&nbsp;</strong>for the behaviors the leader could most benefit from doing more.</p><p>- Consider one's role in the organization, take into account that all leaders should also be helping teams and the organization strive to demonstrate these behaviors.</p>",
    END_MESSAGE_PLACEHOLDER: {
        "buttonName": "Submit",
        "message": '<p><strong>REVIEW AND SUBMIT</strong></p><p><br></p><p>If you would like to review or change any of your responses, you may use the back arrow button at the bottom right of the screen.</p><p><br></p><p>Once you are finished, please click the submit button below.</p><p><br></p><p>Thank you again for completing this 360° feedback form. Your feedback is appreciated and will help inform this leaders development.</p'
    },
    ERROR_TOAST_MODIFY_PROJECT: "Failed to update project details",
    ERROR_TOAST_CREATE_PROJECT: "Failed to create project",
    loaderButton: {
        classes: ["loader-button"],
        loaderClass: ["xs white"],
    },
    BUTTON_NAME: "Create Project",
    LOADING_SPINNER_TEXT: "Creating projects from the file...",
    QUEUED:"Queued"
}