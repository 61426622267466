import { v4 as uuidv4 } from 'uuid'
import { deepCopy } from './deepCopy';
import { generateGoalsForSurveyEditor, generateLikertObject } from './newEditSurveyUtils';
import { COHORT_SURVEY_TYPE, FEEDBACK_360_SURVEY, FEEDBACK_360_TYPE, FEEDBACK_GOAL_IDS, REDIRECTION_CONSTANTS } from '../../config/questionTypeConstants';
import { KEY_CONSTANTS, TRANSLATION_KEYS_CHINESE, TRANSLATION_KEYS_VALUES_CHINESE, TRANSLATION_KEYS_VALUES_SIMPLIFIED_CHINESE } from '../Routes/SurveyAnsweringPage/childrens/RenderQuestionSection/constants';

const answerableQuestions = ["Text Entry", "Multiple Choice", "Matrix", "multipleOption"]
const newEditSurveyTypes = ["ratingScale", "matrix", "ranking", "employerNetPromoterScore"]

export const divideQuestionSetByPageBreaks = (data, demographicsData, isFromQuestionSet) => {
    let questionSet = data.questionSet
    let blockData = [];
    if (data &&
        data.isLocalizationEnabled &&
        data.translationLanguages &&
        data.translationLanguages.length) {
        let generateTranslate = generateLanguageTranslationObject(data)
        blockData.push(generateTranslate)
    }
    // eslint-disable-next-line
    let questionSetHasPageBreak = false;
    if (questionSet && questionSet.length) {
        questionSet.forEach((block) => {
            let startIndex = 0;
            let pageBreakIndexes = []
            let blockHasPageBreak = false;
            block && block.questions && block.questions.forEach((item, index) => {
                if (item.type === 'Page Break') {
                    pageBreakIndexes.push(index);
                    blockHasPageBreak = true;
                    questionSetHasPageBreak = true;
                }
            })
            if (blockHasPageBreak) {
                pageBreakIndexes.forEach((element, index) => {
                    let questionArray = [...block.questions]
                    blockData.push({
                        blockId: block.blockId ? block.blockId : uuidv4(),
                        type: replaceTypes(questionArray[0].type),
                        goalId: block.goalId,
                        isMultipleOption: block.isMultipleOption,
                        isDemographic: block.isDemographic,
                        dimension: block.dimension,
                        questions: questionArray.splice(startIndex, element - startIndex)
                    })
                    questionArray = [...block.questions]
                    if (index === pageBreakIndexes.length - 1) {
                        blockData.push({
                            blockId: block.blockId ? block.blockId : uuidv4(),
                            type: replaceTypes(questionArray[0].type),
                            goalId: block.goalId,
                            isMultipleOption: block.isMultipleOption,
                            isDemographic: block.isDemographic,
                            dimension: block.dimension,
                            questions: questionArray.splice(element + 1, questionArray.length)
                        })
                    }
                    startIndex = element + 1;
                })
            } else {
                blockData.push({ ...block, blockId: block.blockId ? block.blockId : uuidv4(), })
            }
        })
    }
    let updatedBlockDataWithFollowUp = isFromQuestionSet ? blockData : updatedBlockData(blockData, questionSet, demographicsData)
    return updatedBlockDataWithFollowUp
}

export const addAdditionalElementsByCondition = (config, isPreview, isAnswerSet, demographicData) => {
    let removableGoalIds = []
    const questionSetKey = isPreview ? 'questions' : 'questionSet'
    if (config.data && config.data[questionSetKey] && config.data[questionSetKey].length &&
        (config.data.isAnswerSetModified || !isAnswerSet)) {
        if (config.data.aboutSurvey) {
            config.data[questionSetKey].unshift(getDescriptionBlock(
                config.data.aboutSurvey))
        }
        config.data[questionSetKey].forEach(block => {
            if (block.isDefault) {
                if (block.isConditionalTopicSection) {
                    let isThisGoalIdHidden = checkIfHrisBasedRuleSatisfied(block, demographicData)
                    if (isThisGoalIdHidden) {
                        removableGoalIds.push(block.goalId)
                    }
                }
                block.questions[0].matrixQuestions.forEach(question => {
                    question.englishQuestion = question.text
                    if (question.haveFollowUpQuestion && question.followUpQuestions) {
                        question.followUpQuestions.forEach(followUpQuestion => {
                            if (followUpQuestion.type === "Matrix") {
                                followUpQuestion.matrixQuestions.forEach(followUpMatrixQuestion => {
                                    followUpMatrixQuestion.englishQuestion = followUpMatrixQuestion.text
                                })
                            }
                            else if (followUpQuestion.type === "Text Entry") {
                                followUpQuestion.englishQuestion = followUpQuestion.question
                            }
                        })
                    }
                })
                block.questions[0].options.forEach(option => {
                    option.englishLabel = option.label
                })
                if (block.haveFollowUpQuestion && block.followUpQuestions && block.followUpQuestions.length) {
                    block.followUpQuestions.forEach((item) => {
                        if (answerableQuestions.includes(item.type)) {
                            item.englishQuestion = item.question
                        }

                    })
                }
            }
            else {
                block.questions && block.questions.forEach(question => {
                    if (answerableQuestions.includes(question.type)) {
                        question.englishQuestion = question.question
                        question.options.forEach(option => {
                            option.englishLabel = option.label
                            if (option.followUpQuestions) {
                                option.followUpQuestions.forEach((followUp) => {
                                    if (followUp.type === "Matrix") {
                                        followUp.matrixQuestions.forEach((matrix) => {
                                            matrix.englishQuestion = matrix.text
                                        })
                                        followUp.options.forEach((option) => {
                                            option.englishLabel = option.label
                                        })
                                    }
                                    else if (followUp.type === "Text Entry") {
                                        followUp.englishQuestion = followUp.question
                                    }
                                })

                            }
                        })
                    }
                })
            }
      });
      if (config.data.endMessage) {
        config.data[questionSetKey].push({
          endMessage: config.data.endMessage,
          isFinalPage: true,
        });
      } else {
        config.data[questionSetKey].push({ isFinalPage: true });
      }
    }
    let filteredConfig = config.data[questionSetKey]
    if (removableGoalIds && removableGoalIds.length > 0) {
        removableGoalIds.forEach((removingGoalId) => {// eslint-disable-next-line
            filteredConfig = filteredConfig.filter((item) => item.goalId != removingGoalId)
        })
        config.data[questionSetKey] = filteredConfig
    }


    return config

}



export const getDescriptionBlock = (description) => {
    return {
        dimension: "",
        isWelcomeMessage: true,
        blockId: uuidv4(),
        type: "descriptive",
        questions: [
            {
                question: description,
                questionLabel: "",
                isSelected: true,
                isCustom: true,
                questionDimension: "",
                qid: uuidv4(),
                type: "Descriptive Text",
                options: []
            }
        ],
        goalId: uuidv4()
    }
}

const generatePageBreakObjectForNonFollowUp = (block, matrixQuestion, isFromCombinedMatrix) => {
        let matrix = {
        ...block, type: block.type ? block.type : "likert",
        questions: [{ ...block.questions[0], isSplittedTopic: true, matrixQuestions: !isFromCombinedMatrix ? [{ ...matrixQuestion, followUpQuestions: [] }] : matrixQuestion, }],
        followUpQuestions: [],
    }
    let resultedMatrix = matrix.type !== "likert" ? matrix : block.questions[0].optionsCount ? matrix : generateLikertObject(matrix)
    return resultedMatrix
}
const makeBlockDataLevelFollowUp = (question, submittedAnswer, blockId, questions) => {
    if (question.type && question.type === "Text Entry") {
        return {
            isMultipleOptionFollowUp: question.isMultipleOptionFollowUp,
            isFromBlockLevelFollowUp:true,
            selectedId: question.selectedId,
            isSelected: true,
            isStatic: true,
            isTemplate: true,
            dimension: question.dimension,
            goalId: question.goalId,
            condition: question.condition,
            type: replaceTypes(question.type),
            selectedGoalId: question.selectedBlockId,
            selectedBlockId:blockId,
            isTextEntryOnly: true,
            questions: [{
                ...question,
                answer: question.answer,
                question: generateCommentQuestionsWithChoice(question.question, submittedAnswer),
                selectedGoalId: question.selectedBlockId,
                selectedBlockId:blockId,
                isFromBlockLevelFollowUp:true,
                selectedMatrixQuestionQuestionId: question.staticQuestionId,
                selectedQuestionId: questions.qid
            }]
        }
    }
    else {
        return{
            isMultipleOptionFollowUp: question.isMultipleOptionFollowUp,
            selectedId: question.selectedId,
            isFromBlockLevelFollowUp:true,
            isSelected: true,
            isStatic: true,
            isTemplate: true,
            dimension: question.dimension,
            goalId: question.goalId,
            selectedBlockId:blockId,
            condition: question.condition,
            type: "likert",
            selectedGoalId:  question.selectedGoalId,
            questions: [{
                isMultipleOptionFollowUp: question.isMultipleOptionFollowUp,
                optionsCount: question.optionsCount ? question.optionsCount : 3,
                answer: question.answer,
                matrixQuestions: generateMatrixQuestionWithChoice(question.matrixQuestions, submittedAnswer, question.condition),
                options: question.options, type: "likert",
                questionDimension: question.questionDimension,
                selectedBlockId:blockId,
                isFromBlockLevelFollowUp:true,
                qid: question.qid,
                questionLabel: question.questionLabel,
                selectedGoalId:  question.selectedGoalId,
                selectedMatrixQuestionQuestionId: question.staticQuestionId,
                selectedQuestionId: questions.qid

            }]
        }
    }
}
const makeQuestionLevelFollowUp = (question, submittedAnswer, matrixQuestionDetails, currentBlock) => {
    if (question.type && question.type === "Text Entry") {
        return {
            blockId: uuidv4(),
            isMultipleOptionFollowUp: question.isMultipleOptionFollowUp,
            selectedId: question.selectedId,
            isOutcomeVariable: true,
            isSelected: true,
            isStatic: true,
            isTemplate: true,
            isQuestionLevelFollowUp: true,
            dimension: question.dimension,
            goalId: question.goalId,
            condition: question.condition,
            type: replaceTypes(question.type),
            selectedGoalId: question.isMultipleOptionFollowUp ? currentBlock.blockId : question.selectedGoalId,
            isTextEntryOnly: true,
            questions: [{
                ...question,
                answer: question.answer,
                question: generateCommentQuestionsWithChoice(question.question, submittedAnswer),
                isQuestionLevelFollowUp: true,
                selectedGoalId: question.isMultipleOptionFollowUp ? currentBlock.blockId : question.selectedGoalId,
                selectedMatrixQuestionQuestionId: matrixQuestionDetails.staticQuestionId,
                selectedQuestionId: matrixQuestionDetails.id
            }]
        }
    }
    else if (question.isRedirectionPage) {
        return {
            ...question,
            selectedGoalId: question.isMultipleOptionFollowUp ? currentBlock.blockId : question.selectedGoalId,
            isQuestionLevelFollowUp: true,
        }
    }
    else {
        return {
            blockId: uuidv4(),
            isMultipleOptionFollowUp: question.isMultipleOptionFollowUp,
            selectedId: question.selectedId,
            isOutcomeVariable: true,
            isSelected: true,
            isStatic: true,
            isTemplate: true,
            isQuestionLevelFollowUp: true,
            dimension: question.dimension,
            goalId: question.goalId,
            condition: question.condition,
            type: "likert",
            selectedGoalId: question.isMultipleOptionFollowUp ? currentBlock.blockId : question.selectedGoalId,
            questions: [{
                isMultipleOptionFollowUp: question.isMultipleOptionFollowUp,
                optionsCount: question.optionsCount ? question.optionsCount : 3,
                answer: question.answer,
                matrixQuestions: generateMatrixQuestionWithChoice(question.matrixQuestions, submittedAnswer, question.condition),
                options: question.options, type: "likert",
                questionDimension: question.questionDimension,
                qid: question.qid, questionLabel: question.questionLabel,
                isQuestionLevelFollowUp: true,
                selectedGoalId: question.isMultipleOptionFollowUp ? currentBlock.blockId : question.selectedGoalId,
                selectedMatrixQuestionQuestionId: matrixQuestionDetails.staticQuestionId,
                selectedQuestionId: matrixQuestionDetails.id

            }]
        }
    }


}

const checkConditionForDisplay = (questionDetails, questionSet) => {
    // eslint-disable-next-line
    let ruleSatisfied = false
    let submittedAnswer
    let conditionToDisplay = questionDetails.type === "Text Entry" ? questionDetails.condition : questionDetails.matrixQuestions[0].condition
    let staticId = questionDetails.type === "Text Entry" ? questionDetails.selectedQuestionStaticId : questionDetails.matrixQuestions[0].selectedQuestionStaticId // eslint-disable-next-line
    if (!Array.isArray(conditionToDisplay) && conditionToDisplay === "" || conditionToDisplay === undefined) {
        ruleSatisfied = true
    }
    else {
        if (conditionToDisplay && Array.isArray(conditionToDisplay)) {
            if (conditionToDisplay.length > 0) {
                questionSet.forEach((block, key) => {
                    if (block.goalId === questionDetails.selectedGoalId) {
                        if (block.questions[0] && block.questions[0].answer) {
                            let parsedAnswers = JSON.parse(block.questions[0].answer);
                            parsedAnswers.forEach((answerGroup) => {
                                if (answerGroup.staticQuestionId === staticId) {
                                    ruleSatisfied = conditionToDisplay.includes(`${answerGroup.answer}`)
                                    let labelForSubmittedAnswer = block.questions[0].options.filter((item) =>
                                        // eslint-disable-next-line
                                        item.value == answerGroup.answer
                                    )
                                    submittedAnswer = labelForSubmittedAnswer[0].label
                                }
                            })
                        }

                    }
                })
            }
            else {
                ruleSatisfied = true
            }

        }
    }


    return { submittedAnswer, ruleSatisfied: true }
}

const generateMatrixQuestionWithChoice = (matrixQuestions, submittedAnswer, condition) => {
    let matrixQuestionArray
    if (matrixQuestions && matrixQuestions[0]) {
        let indexOfString = matrixQuestions[0].text.search("{choice}")
        if (indexOfString >= 0) {
            let updatedText = matrixQuestions[0].text.replace("{choice}", submittedAnswer)
            matrixQuestionArray = [{ ...matrixQuestions[0], text: updatedText, condition: condition }]
        }
        else {
            matrixQuestionArray = [{ ...matrixQuestions[0], condition: condition }]
        }

    }
    return matrixQuestionArray

}


const updatedBlockData = (blockData, questionSet, demographicsData, isKeyAddingSurvey) => {
    let updatedBlockData = []
    let nonFollowUpMatrix = []// eslint-disable-next-line
    let isBlockContainsFollowUp = false
    if (blockData && blockData.length) {
        blockData.forEach((block, index) => {
            if (block.isDefault || block.isCustomTopic) {
                block.questions[0].matrixQuestions.forEach((questionDetails, index) => {
                    if (questionDetails.HTMLText) {
                        questionDetails.HTMLText = replaceQuestionIfDemographicsBracesExists(questionDetails.HTMLText, demographicsData)
                    }
                    else {
                        questionDetails.text = replaceQuestionIfDemographicsBracesExists(questionDetails.text, demographicsData)
                    }

                    if (questionDetails.haveFollowUpQuestion) {
                        isBlockContainsFollowUp = true
                        if (nonFollowUpMatrix.length > 0) {
                            let combinedFollowUp = generatePageBreakObjectForNonFollowUp(block, nonFollowUpMatrix, true, isKeyAddingSurvey)
                            updatedBlockData.push(combinedFollowUp)
                            nonFollowUpMatrix = []
                        }
                        let createNewObjectForNonFollowUpPageBreak = generatePageBreakObjectForNonFollowUp(block, questionDetails, false, isKeyAddingSurvey)
                        updatedBlockData.push(createNewObjectForNonFollowUpPageBreak)
                        questionDetails.followUpQuestions.forEach((question, key) => {
                            let { submittedAnswer, ruleSatisfied } = checkConditionForDisplay(question, questionSet)
                            if (ruleSatisfied) {
                                let makeQuestionLevelFollowUpObject = makeQuestionLevelFollowUp(question, submittedAnswer, questionDetails)
                                updatedBlockData.push(makeQuestionLevelFollowUpObject)
                            }
                            else {
                                if (question.answer) {
                                    question.answer = null
                                }
                            }
                        })
                    }
                    else {
                        nonFollowUpMatrix.push(questionDetails)
                    }

                })
                if (nonFollowUpMatrix.length > 0) {
                    let combinedFollowUp = generatePageBreakObjectForNonFollowUp(block, nonFollowUpMatrix, true, isKeyAddingSurvey)
                    updatedBlockData.push(combinedFollowUp)
                    nonFollowUpMatrix = []
                }
                // if (!isBlockContainsFollowUp && nonFollowUpMatrix.length == 0) {
                //     updatedBlockData.push(block)
                // }
            }

            else if (block.isTranslationSelection) {
                updatedBlockData.push(block)
            }

            else {
               block.questions && block.questions.forEach(question => {
                    if (answerableQuestions.includes(question.type)) {
                        if (question.HTMLQuestion) {
                            question.HTMLQuestion = replaceQuestionIfDemographicsBracesExists(question.HTMLQuestion, demographicsData)
                        }
                        else {
                            question.question = replaceQuestionIfDemographicsBracesExists(question.question, demographicsData)
                        }
                    }
                    else {

                    }

                })
                if (block.questions && block.questions.length !== 0) {
                    updatedBlockData.push(block)

                }
                else if (block.type === "priorityComments" || block.type === "summaryRating") {
                    updatedBlockData.push(block)

                }
                if (block.isDemographic || block.isMultipleOption) {
                    block.questions && block.questions.forEach((question) => {
                        if(question.haveFollowUpFromBlockLevel){
                            if(question.followUpQuestions && question.followUpQuestions.length > 0 ){
                                question.followUpQuestions.forEach((followUp) =>{
                                    let generatedFollowUp = makeBlockDataLevelFollowUp(followUp, undefined, block.blockId, question)
                                    updatedBlockData.push(generatedFollowUp)
                                })
                            }
                        }
                        if (question.haveFollowUpQuestion) {
                            question.options.forEach((optionItem) => {
                                if (optionItem.followUpQuestions && optionItem.followUpQuestions.length > 0) {
                                    optionItem.followUpQuestions.forEach((followupOptions) => {
                                        let generatedFollowUp = makeQuestionLevelFollowUp(followupOptions, undefined, optionItem, block)
                                        updatedBlockData.push(generatedFollowUp)
                                    })
                                }
                            })
                        }
                        
                    })
                }  

            }
            if (block.haveFollowUpQuestion) {
                if (block.followUpQuestions && block.followUpQuestions.length) {
                    block.followUpQuestions.forEach((item) => {
                        updatedBlockData.push({
                            type: replaceTypes(item.type),
                            isTextEntryOnly: replaceTypes(item.type) === "textEntry",
                            dimension: item.dimension,
                            questions: [item],
                        })
                    })
                }
            }
            isBlockContainsFollowUp = false
            if (block) {
                let type = block.questions && block.questions[0] && block.questions[0].type ? block.questions[0].type : block.type
                block.type = replaceTypes(type)
            }
        })

    }
    if (questionSet) {
        const endMessageObject = questionSet.find(item => item.isFinalPage);
        updatedBlockData.push(endMessageObject)
    }
    return updatedBlockData

}
const generateCommentQuestionsWithChoice = (commentQuestionText, submittedAnswer) => {
    let updatedText
    if (commentQuestionText) {
        let indexOfString = commentQuestionText.search("{choice}")
        if (indexOfString >= 0) {
            updatedText = commentQuestionText.replace("{choice}", submittedAnswer)
        }
        else {
            updatedText = commentQuestionText
        }
    }
    return updatedText
}

const getReplaceString = (demographicsData, replaceItem) => {
    if (demographicsData) {
        return demographicsData[replaceItem]
    }
}

const replaceQuestionIfDemographicsBracesExists = (questionText, demographicsData) => {
    let found = [],
        rxp = /{([^}]+)}/g,
        mat;
    let updatedText = questionText
    // eslint-disable-next-line
    while (mat = rxp.exec(questionText)) {
        found.push(mat[1]);
    }
    if (found.length > 0) {
        found.forEach((replaceItem) => {
            updatedText = updatedText.replace(`{${replaceItem}}`, getReplaceString(demographicsData, replaceItem))
        })
    }
    return updatedText
}

export const checkIfHrisBasedRuleSatisfied = (block, demographicData) => {
    let isShowTopicBlock = false
    if (block.hrisBasedCondition && block.hrisBasedCondition.length > 0) {
        let condionalDemographics = block.hrisBasedCondition[0] && block.hrisBasedCondition[0].selectedDemographics
        if (condionalDemographics && demographicData && demographicData) {
            if (block.hrisBasedCondition[0][condionalDemographics]) {
                let enteredUserDemographics = demographicData[condionalDemographics]
                isShowTopicBlock = block.hrisBasedCondition[0][condionalDemographics].includes(enteredUserDemographics)
                if (block.hrisBasedCondition[0].showQuestion === "Show" && isShowTopicBlock) {
                    return false
                }
                else if (block.hrisBasedCondition[0].showQuestion === "Hide") {
                    return isShowTopicBlock
                }
                else return true
            }

        }
    }
    return isShowTopicBlock
}

const generateLanguageTranslationObject = (data) => {
    return {
        isTranslationSelection: true,
        translationLanguages: data.translationLanguages ? data.translationLanguages : [],
        type: "translation",
        blockId: uuidv4(),
    }
}

export const checkIfPreviewLink = (previewLnk) => {
    return window.location.href.includes(previewLnk)
}

export const getTextForTranslation = ({ language, defaultTexts, translatedTexts, key }) => {
    if (language === "English") {
        return `${defaultTexts[key]}`
    }
    else if (translatedTexts[key]) { return `${translatedTexts[key]}` }
    else return `${defaultTexts[key]}`
}

export const getTextForManualTranslation = ({ language, defaultTexts, translatedTexts, key }) => {
    if (language === "English") {
        return `${defaultTexts[key]}`
    } else if (language === "繁體中文" && TRANSLATION_KEYS_CHINESE.includes(key)){
        return TRANSLATION_KEYS_VALUES_CHINESE[key]
    }
    else if (language === "简体中文" && TRANSLATION_KEYS_CHINESE.includes(key)) {
        return TRANSLATION_KEYS_VALUES_SIMPLIFIED_CHINESE[key]
    }
    else if (translatedTexts[key]) { return `${translatedTexts[key]}` }
    else return `${defaultTexts[key]}`
}

export const getWelcomeMessageText = ({ language, translatedDescription, currentMessage, translatedTexts, key }) => {
    if (language === "English" || language === "繁體中文" || language === "简体中文") {
        return `${currentMessage}`
    }
    else if (translatedDescription && translatedDescription[language]) { return translatedDescription[language] }
    else if (translatedTexts[key]) {
        return `${translatedTexts[key]}`
    }
    else return `${currentMessage}`
}

export const getQuestionTranslation = ({ language, question, key,isCustomMatrix }) => {
    if (question[key] && question[key].length === 0) {
        return "..."
    }
    if (language === "English") {
        if(isCustomMatrix){
            if(question.HTMLText){
                return `${question.HTMLText}`
            }else if(KEY_CONSTANTS.LABEL in question){
                return `${question[KEY_CONSTANTS.LABEL]}`
            }else if(KEY_CONSTANTS.NORMAL_TEXT in question){
                return `${question[KEY_CONSTANTS.NORMAL_TEXT]}`
            }
        }else if(question[key]){
            return `${question[key]}`
        }
        else{
            return `${question[KEY_CONSTANTS.LABEL]}`
        }
    }
    else if (question[language]) { 
        return `${question[language]}` 
    }
    else return `${question[key]}`
}

export const getCurrentQuestionSetDetails = (instance) => {
    let answeredBlock = []
    let answer = undefined
    if (FEEDBACK_GOAL_IDS.includes(instance.props.questionDetails.goalId)) {
        return { answer: instance.props.questionDetails.questions }
    }
    else if (instance.props.questionDetails.isRedirectionPage) {
        return {}
    }
    else if(instance.props.questionDetails.isFromBlockLevelFollowUp && instance.props.questionDetails.isMultipleOptionFollowUp){
        let blockLevelFollowUpBlock = instance.props.questionSet.find(item => item.blockId === instance.props.questionDetails.selectedBlockId)
        answeredBlock = blockLevelFollowUpBlock.questions[0].followUpQuestions.filter((item) => item.qid === instance.props.questionDetails.questions[0].qid)
        answer = answeredBlock[0].answer
    }
    else if (instance.props.questionDetails.isMultipleOptionFollowUp) {
        let questionLevelFollowUpBlock = instance.props.questionSet.find(item => item.blockId === instance.props.questionDetails.selectedGoalId)
        let selectedOptionBlock = questionLevelFollowUpBlock.questions[0].options.find(item => item.id === instance.props.questionDetails.questions[0].selectedQuestionId)
        answeredBlock = selectedOptionBlock.followUpQuestions.filter((item => item.qid === instance.props.questionDetails.questions[0].qid))
        answer = answeredBlock[0].answer
    }
    else if ((instance.props.questionDetails.isDefault || instance.props.questionDetails.isCustomTopic) && !instance.props.questionDetails.isQuestionLevelFollowUp) {
        answeredBlock = instance.props.questionSet.filter(item => item.goalId === instance.props.questionDetails.goalId)
        answer = answeredBlock[0].questions[0].answer
    }
    else if (instance.props.questionDetails.questions && instance.props.questionDetails.questions[0].isFollowUpQuestion && !instance.props.questionDetails.isQuestionLevelFollowUp) {
        let answeredGoal = instance.props.questionSet.filter(item => item.goalId === instance.props.questionDetails.questions[0].goalId)
        answeredBlock = answeredGoal[0].followUpQuestions.filter(item => item.staticQuestionId === instance.props.questionDetails.questions[0].staticQuestionId)
        answer = answeredBlock[0].answer
    }
    else if (instance.props.questionDetails.dimension === "Comments" && !instance.props.questionDetails.isQuestionLevelFollowUp) {
        let answeredCommentBlock = instance.props.questionSet.filter(item => item.blockId === instance.props.questionDetails.blockId)
        answeredBlock = answeredCommentBlock[0].questions.filter(item => item.staticQuestionId === instance.props.questionDetails.questions[0].staticQuestionId)
        answer = answeredBlock[0].answer

    }
    else if (instance.props.questionDetails.isDemographic || instance.props.questionDetails.isMultipleOption || instance.props.questionDetails.type === "yesOrNo") {
        let optionBlock = instance.props.questionSet.filter(item => item.blockId === instance.props.questionDetails.blockId)
        answeredBlock = optionBlock[0].questions.filter(item => item.staticQuestionId === instance.props.questionDetails.questions[0].staticQuestionId)
        answer = answeredBlock[0].answer
    }

    else if (instance.props.questionDetails.isQuestionLevelFollowUp) {
        if (instance.props.questionDetails.questions[0] && instance.props.questionDetails.questions[0].matrixQuestions) {
            let questionLevelFollowUpBlock = instance.props.questionSet.filter(item => item.goalId === instance.props.questionDetails.questions[0].selectedGoalId)
            let selectedMatrixQuestion = questionLevelFollowUpBlock[0].questions[0].matrixQuestions.filter(item => item.staticQuestionId === instance.props.questionDetails.questions[0].selectedMatrixQuestionQuestionId)
            let answeredMatrixBlock = selectedMatrixQuestion[0].followUpQuestions.filter(item => item.qid === instance.props.questionDetails.questions[0].qid)
            answeredBlock = [{ ...answeredMatrixBlock[0].matrixQuestions[0], questions: [{ answer: answeredMatrixBlock[0].matrixQuestions[0].answer }] }]
            answer = answeredMatrixBlock[0].answer
        }
        else {
            let questionLevelFollowUpBlock = instance.props.questionSet.filter(item => item.goalId === instance.props.questionDetails.questions[0].selectedGoalId)
            let selectedMatrixQuestion = questionLevelFollowUpBlock[0].questions[0].matrixQuestions.filter(item => item.staticQuestionId === instance.props.questionDetails.questions[0].selectedMatrixQuestionQuestionId)
            answeredBlock = selectedMatrixQuestion[0].followUpQuestions.filter(item => item.qid === instance.props.questionDetails.questions[0].qid)
            answer = answeredBlock[0].answer
        }

    }
    else if (newEditSurveyTypes.includes(instance.props.questionDetails.type)) {
        answeredBlock = instance.props.questionSet.filter(item => item.blockId === instance.props.questionDetails.blockId)
        answer = answeredBlock[0].questions[0].answer
    }
    if (answeredBlock[0] && answer !== undefined) {
        return { answer: answer }
    }
    else return {}

}
/* to delete follow up data from question set */
// const deleteFollowUp = (questionSet) => {
//     let updatedData = []
//     let questionSetCopy = deepCopy(questionSet)
//     if (questionSetCopy && questionSetCopy.length) {
//         questionSetCopy.forEach((block, index) => {
//             if (block.isDefault || block.isCustomTopic) {
//                 block.questions[0].matrixQuestions.forEach((questionDetails, index) => {
//                     if (questionDetails.haveFollowUpQuestion) {
//                         delete block.questions[0].matrixQuestions[index].haveFollowUpQuestion
//                         delete block.questions[0].matrixQuestions[index].followUpQuestion
//                     }
//                 })
//             }
//             if (block.haveFollowUpQuestion) {
//                 delete block.haveFollowUpQuestion
//                 delete block.followUpQuestions
//             }
//             updatedData.push(block)
//         })
//     }
//     return updatedData
// }
export const replaceTypes = (type) => {
    switch (type) {
        case "Multiple Choice":
            return "multipleChoice"
        case "Text Entry":
            return "textEntry"
        case "Matrix":
            return "matrix"
        case "Descriptive Text":
            return "descriptive"
        default:
            return type
    }

}

export const generateUpdatedEditSurveyBlock = (data) => {
    let blockDataCopy = deepCopy(data.questionSet)
    if (data &&
        data.isLocalizationEnabled &&
        data.translationLanguages &&
        data.translationLanguages.length) {
        let generateTranslate = generateLanguageTranslationObject(data)
        blockDataCopy.splice(0, 0, generateTranslate,)
    }
    let newBlockData = data && data.projectType && data.projectType === "360FeedbackDynamicForAppliedMaterials" ? blockDataCopy : updatedBlockData(blockDataCopy, data.questionSet)
    let hai = generateGoalsForSurveyEditor({ questionSet: newBlockData })
    return newBlockData
}

export const generateQuestionSetWithBlockId = (questionSetDetails) => {
    let questionSetCopy = divideQuestionSetByPageBreaks(questionSetDetails, null, true)
    let updatedQuestionSet = generateGoalsForSurveyEditor({ questionSet: questionSetCopy }, true)
    return updatedQuestionSet

}

export const getFilteredMenuData = (goals) => {
    let goalsCopy = deepCopy(goals)
    let filteredGoals = []
    filteredGoals = goalsCopy.filter((item) => !item.isHiddenFromEditSurvey)
    return filteredGoals

}


export const checkIfJson = (item) => {
    try {
        JSON.parse(item);
    } catch (e) {
        return false;
    }
    return true;
}


export const checkIfFeedback360Survey = (projectType) => {
    if (FEEDBACK_360_TYPE.includes(projectType)) {
        return true
    }
    else {
        return false
    }
}

export const checkWheatherCohortSurvey = (projectType) => {
    if (projectType === COHORT_SURVEY_TYPE) {
        return true
    }
    else {
        return false
    }
}

export const gatherTranslationKeys = (data, languages) => {
    let currentObject = {}
    if (data && languages) {
        languages.forEach((language) => {
            if (language !== "English") {
                currentObject[language] = data[language] ? data[language] : ""

            }
        })
    }
    return currentObject
}