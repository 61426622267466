import {
  FETCH_SURVEY_LIST_STARTED,
  FETCH_SURVEY_LIST_SUCCESS,
  FETCH_SURVEY_LIST_FAILED,
  FETCH_COMPLETE_SURVEY_LIST_STARTED,
  FETCH_COMPLETE_SURVEY_LIST_SUCCESS,
  FETCH_COMPLETE_SURVEY_LIST_FAILED,
} from "../../constants";
import { LIST_SURVEY_URL } from "../../../../config/constants";
import {
  NLPCommonReqBodies,
  getSurveyListQueryParams,
  listSurveyCommonBody,
} from "../../../utils/actionOperations";
import { ApiBase } from "../../../utils/apiBase";
import { getOrgIdFromUrl } from "../../../utils/urlProcessor";

export const fetchProjectLists = (params, cb) => {
  const orgId = getOrgIdFromUrl();
  const fetchProps = getSurveyListQueryParams(params);
  const axios = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type: FETCH_SURVEY_LIST_STARTED });
    axios
      .post(LIST_SURVEY_URL, {
        ...NLPCommonReqBodies(true, orgId),
        ...fetchProps,
      })
      .then((res) => {
        dispatch({
          type: FETCH_SURVEY_LIST_SUCCESS,
          payload: { data: res && res.data && res.data.data },
        });
        cb && cb(res);
      })
      .catch((err) =>
        dispatch({
          type: FETCH_SURVEY_LIST_FAILED,
          payload: { error: err && err.message },
        })
      );
  };
};

export const fetchCompletedSurveyProject = (params, cb) => {
  const orgId = getOrgIdFromUrl();
  const fetchProps = getSurveyListQueryParams(params);
  const axios = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type: FETCH_COMPLETE_SURVEY_LIST_STARTED });
    axios
      .post(LIST_SURVEY_URL, {
        ...listSurveyCommonBody(true, orgId),
        ...fetchProps,
      })
      .then((res) => {
        dispatch({
          type: FETCH_COMPLETE_SURVEY_LIST_SUCCESS,
          payload: { data: res && res.data && res.data.data },
        });
        cb && setTimeout(() => { cb(); }, 100);
      })
      .catch((err) =>
        dispatch({
          type: FETCH_COMPLETE_SURVEY_LIST_FAILED,
          payload: { error: err && err.message },
        })
      );
  };
};
