import { fetchPayload } from "../../../utils/reducerOperations";
import * as helper from "./Helpers";
import {
  COMMENTS_FETCH_SENTIMENTS_STARTED,
  COMMENTS_FETCH_SENTIMENTS_SUCCESS,
  COMMENTS_FETCH_SENTIMENTS_FAILED,
  GET_BATCH_COMMENT_QUESTIONS_STARTED,
  GET_BATCH_COMMENT_QUESTIONS_SUCCESS,
  GET_BATCH_COMMENT_QUESTIONS_FAILED,
  UPDATE_COMMENT_QUESTION_ID,
  DOWNLOADS_COMMENTS_BY_BATCH_ID_START,
  DOWNLOADS_COMMENTS_BY_BATCH_ID_SUCCESS,
  DOWNLOADS_COMMENTS_BY_BATCH_ID_FAILED,
  GET_COMMENT_THEMES_STARTED,
  GET_COMMENT_THEMES_SUCCESS,
  GET_COMMENT_THEMES_FAILED,
  UPDATE_GET_COMMENTS_SELECTED_TOPIC,
  UPDATE_COMMENTS_SEARCH_KEY,
  FETCH_DASHBOARD_COMMENTS_FILTER_START,
  FETCH_DASHBOARD_COMMENTS_FILTER_SUCCESS,
  FETCH_DASHBOARD_COMMENTS_FILTER_FAILED,
  CHANGE_DASHBOARD_SEARCH_FILTER,
} from "../../constants";
import { InitialState } from "./InitialState";

const CommentsFetchSentimentsDataReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case COMMENTS_FETCH_SENTIMENTS_STARTED:
      return helper.fetchCommentsSentimentsDataStart(state, payload);
    case COMMENTS_FETCH_SENTIMENTS_SUCCESS:
      return helper.fetchCommentsSentimentsDataSuccess(state, payload);
    case COMMENTS_FETCH_SENTIMENTS_FAILED:
      return helper.fetchCommentsSentimentsDataFailed(state, payload);
    case GET_BATCH_COMMENT_QUESTIONS_STARTED:
      return helper.getBatchCommentQuestionsStart(state, payload);
    case GET_BATCH_COMMENT_QUESTIONS_SUCCESS:
      return helper.getBatchCommentQuestionsSuccess(state, payload);
    case GET_BATCH_COMMENT_QUESTIONS_FAILED:
      return helper.getBatchCommentQuestionsFailed(state, payload);
    case UPDATE_COMMENT_QUESTION_ID:
      return helper.updateCommentQuestionId(state, payload);
    case DOWNLOADS_COMMENTS_BY_BATCH_ID_START:
      return helper.downloadCommentBatchStart(state, payload);
    case DOWNLOADS_COMMENTS_BY_BATCH_ID_SUCCESS:
      return helper.downloadCommentBatchSuccess(state, payload);
    case DOWNLOADS_COMMENTS_BY_BATCH_ID_FAILED:
      return helper.downloadCommentBatchFail(state, payload);
    case GET_COMMENT_THEMES_STARTED:
      return helper.getCommentThemeStart(state, payload);
    case GET_COMMENT_THEMES_SUCCESS:
      return helper.getCommentThemeSuccess(state, payload);
    case GET_COMMENT_THEMES_FAILED:
      return helper.getCommentThemeFail(state, payload);
    case UPDATE_GET_COMMENTS_SELECTED_TOPIC:
      return helper.getCommentsSelectedTopic(state, payload);
    case UPDATE_COMMENTS_SEARCH_KEY:
      return helper.updateSearchkey(state, payload);
    case FETCH_DASHBOARD_COMMENTS_FILTER_START:
      return helper.fetchCommentsDashBoardFilterStart(state, payload);
    case FETCH_DASHBOARD_COMMENTS_FILTER_SUCCESS:
      return helper.fetchCommentsDashBoardFilterSuccess(state, payload);
    case FETCH_DASHBOARD_COMMENTS_FILTER_FAILED:
      return helper.fetchCommentsDashBoardFilterFail(state, payload);
    case CHANGE_DASHBOARD_SEARCH_FILTER:
      return helper.changeDashboardSearchFilter(state, payload);
    default:
      return { ...state };
  }
};

export default CommentsFetchSentimentsDataReducer;
