import { SURVEY_EDITOR_MATRIX_CHANGE_LABELS,SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL,SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL } from "../../../../../constants";

export const matrixChangeLabels = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_CHANGE_LABELS, payload });
    }
};

export const matrixAddOrRowOrCol = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL, payload });
    }
};

export const matrixRemoveAddOrRowOrCol = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL, payload });
    }
};