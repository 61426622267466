import { getApiStartState } from "../../../../utils/reducerOperations"

export const getBatchCommentQuestionsStart = (state) => {
    return {
        ...state,
        getBatchCommentQuestionsApi: getApiStartState(),
        isDataFetched:false
    }

}

export const getBatchCommentQuestionsSuccess = (state, payload) => {
    let questionArray=[]
    payload.data.data.forEach((item,index)=>
    questionArray[item.questionId]={data:null,isProcessing:false,isDataAlreadyFetched:false,topics:[]}
    )
    return {
        ...state,
        ...questionArray,
        getBatchCommentQuestionsApi:{ isProcessing: false,
        data: {
            ...payload.data
          },
        error: !payload.status,
        message: payload.message,
        },
        isDataFetched:true,
        selectedQuestionId:payload.data.data[0].questionId
      
    }

}

export const getBatchCommentQuestionsFailed = (state, payload) => {
    return {
        ...state,
        getBatchCommentQuestionsApi: {
            isProcessing: false,
            data: null,
            error: true
        }
    }

}





