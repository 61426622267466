export const fetchCohortSurveyListingStart = (state, payload) => {
    return {
        ...state,
        cohortSurveyList: {
            ...state.cohortSurveyList,
            isProcessing: true,
            error: false, reason: '',
        },
    };
};

export const fetchCohortSurveyListingSuccess = (state, payload) => {
    return {
        ...state,
        cohortSurveyList: {
            isProcessing: false,
            error: false,
            reason: '',
            data: payload.data.data.hits,
            count: payload.data.data.total
        },
    };
};

export const fetchCohortSurveyListingFail = (state, payload) => {
    return {
        ...state,
        cohortSurveyList: {
            ...state.cohortSurveyList,
            isProcessing: false,
            error: true,
            reason: payload.error,
        },
    };
};
